import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  console.log(post)
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <PageTitle title={post.frontmatter.title} />
      <section class="section blog-wrap">
        <div class="container">
          <div class="row">
            <div class="col blog-post">
              <div>
                <Link to="/insights">
                  <span aria-hidden="true">←</span> Back to Insights
                </Link>
              </div>

              <h3 class="mt-4">{post.frontmatter.title}</h3>

              <div class="mt-1 mb-4 d-block d-sm-flex">
                <div class="post-author mr-3">
                  <i class="fa fa-user"></i>
                  <span class="h6 text-uppercase">By Craig Pickles</span>
                </div>

                <div class="post-info">
                  <i class="fa fa-calendar-check"></i>
                  <span>
                    <time datetime={post.frontmatter.date}>
                      {post.frontmatter.date}
                    </time>
                  </span>
                </div>
              </div>

              <div
                class="post-content"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />

              {post.frontmatter.tags != null &&
                post.frontmatter.tags.length > 0 && (
                  <div class="mt-5 mb-3">
                    <h5 class="d-inline-block mr-3">Tags:</h5>
                    <ul class="list-inline d-inline-block">
                      {post.frontmatter.tags.map(tag => (
                        <li class="list-inline-item">
                          <a href="#!" class={"tag-" + tag}>
                            {tag}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 320)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }
  }
`
